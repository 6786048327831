<template>
  <div class="details">
    <top-nav :topNavData="topNavData" />
    <video
      class="video width100"
      ref="audios"
      id="audios"
      poster="https://image.iriding.cc/video/f2.png"
      x5-video-player-type="h5-page"
      webkit-playsinline="true"
      playsinline="true"
      disablePictureInPicture
      muted
      controls
      controlsList="nofullscreen nodownload noremote footbar noplaybackrate"
    >
      <source src="https://image.iriding.cc/video/f2.mp4" type="video/mp4" />
      您的浏览器不支持Video标签。
    </video>
    <div class="imgone backgrImg height1200" id="brief">
      <div class="marginL50 marginR50">
        <span class="bigText marginT100 color0"> {{ $t("F2.v16") }} </span
        ><br />
        <span class="bigText color0"> {{ $t("F2.v17") }} </span><br />
        <span class="smText marginT46 color3A"
          >{{ $t("F2.v18") }}{{ $t("F2.v19") }}{{ $t("F2.v20") }}</span
        >
      </div>
    </div>
    <div class="imgtwo height1200 back24">
      <div class="item-top">
        <div class="imgM backgrImg">
          <span class="title font38 fontBold color0">{{ $t("F2.v21") }}</span>
        </div>
      </div>
      <ul>
        <li v-for="(item, index) in ul1" :key="'ul1' + index">
          <div>
            <img :class="'img' + index" :src="item.img" alt="" />
          </div>
          <span class="colorW font27">{{ $t(item.text) }}</span>
        </li>
      </ul>
    </div>
    <div class="imgthree height1200">
      <div class="marginL50 marginR50 marginT100">
        <span class="color0 bigText">{{ $t("F2.v26") }}</span>
        <span class="color0 bigText">{{ $t("F2.v27") }}</span>
        <span class="smText color3A marginT30"
          >{{ $t("F2.v28") }}
          <div
            :style="
              ios13
                ? 'margin-top: -0.45rem; margin-left: 2.4rem'
                : ios15
                ? 'margin-top: -0.5rem; margin-left: 2.4rem'
                : 'margin-top: -0.45rem; margin-left: 2.3rem'
            "
            class="font15 positionA color3A"
          >
            「1」
          </div>
        </span>
        <span class="color3A" style="margin-left: 0.23rem"
          >{{ $t("F2.v29") }}{{ $t("F2.v30") }}{{ $t("F2.v31")
          }}{{ $t("F2.v32") }}</span
        >
        <img style="" src="@/assets/image/f2phone/img5.png" alt="" />
        <span class="font24 color4e">{{ $t("F2.v33") }}</span>
      </div>
    </div>
    <div class="imgfour backgrImg textaR">
      <span class="color3A smText marginR50 margin54">{{ $t("F2.v34") }}</span>
      <br />
      <span class="color3A smText marginR50"
        >{{ $t("F2.m34a") }} <sup style="margin-left: -0.1rem">3</sup>
      </span>
    </div>
    <div class="imgfive backgrImg height1200">
      <div class="marginL50 marginR50">
        <span class="color0 bigText marginT100">{{ $t("F2.v35") }}</span
        ><br />
        <span class="color0 bigText">{{ $t("F2.v36") }}</span>
        <span class="color3A smText marginT30"
          >{{ $t("F2.v37") }}{{ $t("F2.v38") }}{{ $t("F2.v38a") }}</span
        ><br />
        <span class="color4e smText positionA bottom83"
          >{{ $t("F2.v39") }}{{ $t("F2.v40") }}{{ $t("F2.v41") }}</span
        >
      </div>
    </div>
    <img style="width: 100%" src="@/assets/image/f2phone/img8.png" alt="" />
    <div class="imgsix backgrImg height1200">
      <div class="marginL50 marginR50">
        <img
          class="marginT100"
          src="@/assets/image/f2phone/tubiao1.png"
          alt=""
        />
        <br />
        <span class="color0 bigText">{{ $t("F2.v43a") }}</span
        ><br />
        <span class="color3A smText sp1"
          >{{ $t("F2.v45") }}{{ $t("F2.v46") }}{{ $t("F2.v47")
          }}{{ $t("F2.v48") }}{{ $t("F2.m47c") }}</span
        >
      </div>
    </div>
    <div class="content2">
      <span class="bigText textaC width100 colorW">{{ $t("F2.v50") }}</span
      ><br />
      <span class="bigText textaC width100 colorW">{{ $t("F2.v51") }}</span>
      <div class="imgOne backgrImg item">
        <div>
          <span class="color0 bigText"
            >{{ $t("F2.v52") }}{{ $t("F2.v53") }}</span
          >
          <span class="color3A smText">{{ $t("F2.v54") }}</span>
        </div>
      </div>
      <div class="imgTwo backgrImg">
        <div class="positionR">
          <div style="margin-left: 0.5rem; margin-right: 0.5rem">
            <span class="color0 bigText"
              >{{ $t("F2.v56") }}{{ $t("F2.v57") }}</span
            >
            <span class="smText color3A">{{ $t("F2.v58") }} </span>
            <sup
              style="margin-left: -0.1rem; margin-top: 0.1rem"
              class="font15 color3A positionA"
              >「2」</sup
            >

            <div style="display: flex; margin-top: 0.4rem" class="item1 floatL">
              <span class="font24 color567">{{ $t("C2.v41") }}</span>
              <div style="display: flex; align-items: center">
                <img
                  style="width: 0.74rem; height: 0.18rem; padding-top: 0.045rem"
                  src="@/assets/image/c2phone/p-icon1.png"
                  alt=""
                />
              </div>
            </div>
            <div
              style="display: flex; margin-top: 0.4rem"
              class="item1 floatL marginL50"
            >
              <span class="font24 colorA18">{{ $t("C2.v42") }}</span>
              <div style="display: flex; align-items: center">
                <img
                  style="width: 0.74rem; height: 0.18rem; padding-top: 0.045rem"
                  src="@/assets/image/c2phone/p-icon2.png"
                  alt=""
                />
              </div>
            </div>
            <br style="clear: both" />
            <ul>
              <li
                :class="index === 0 || index === 1 ? 'marginR30' : ''"
                class="font24"
                v-for="(item, index) in ul3"
                :key="'ul3' + index"
                style="width: 30%"
              >
                <img
                  style="
                    width: 1.1rem;
                    height: 1.1rem;
                    margin-top: 0.3rem;
                    margin-bottom: 0.18rem;
                  "
                  :src="item.img"
                  alt=""
                />
                <span>{{ $t(item.text1) }}</span>
                <span>{{ $t(item.text2) }}</span>
              </li>
              <br style="clear: both" />
            </ul>
          </div>
        </div>
        <img
          style="width: 100%"
          src="@/assets/image/f2phone/img11.png"
          alt=""
        />
      </div>
      <div class="imgThree backgrImg item">
        <div>
          <span class="color0 bigText"
            >{{ $t("F2.v66") }}{{ $t("F2.v67") }}</span
          >
          <span class="smText color3A">{{ $t("F2.v68") }}</span>
        </div>
      </div>
    </div>
    <div class="content4">
      <div class="backgrImg">
        <div>
          <span class="color0 bigText">{{ $t("F2.v69b") }} </span>
          <br />
          <span class="color0 bigText">{{ $t("F2.v69a") }}</span>
          <span class="smText color3A marginT30"
            >{{ $t("F2.v70") }}{{ $t("F2.v72") }}</span
          >
          <div>
            <div>
              <img
                style="width: 0.79rem; height: 0.79rem; margin-bottom: 0.2rem"
                src="@/assets/image/f2phone/s-icon1.png"
                alt=""
              />
              <span class="font24 fw400 color3A">{{ $t("F2.v73") }}</span>
            </div>
            <div class="marginL45">
              <img
                style="width: 0.79rem; height: 0.79rem; margin-bottom: 0.2rem"
                src="@/assets/image/f2phone/s-icon2.png"
                alt=""
              />
              <span class="font24 color4e fw400">{{ $t("F2.v74") }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content3">
      <div class="item1 marginL50 marginR50">
        <span class="color0 bigText marginT100">{{ $t("F2.v75") }}</span
        ><br />
        <span class="color0 bigText">{{ $t("F2.v75a") }}</span
        ><br />
        <span style="position: relative" class="color3A smText marginT30"
          >{{ $t("F2.v76") }}
          <sup
            class="font15 color3A"
            style="
              position: absolute;
              margin-left: -0.17rem;
              margin-top: -0.1rem;
            "
            >「3」</sup
          >&nbsp;&nbsp;{{ $t("F2.v76b") }}{{ $t("F2.v76a") }}</span
        >
        <div class="marginT30">
          <img
            style="width: 0.33rem; height: 0.34rem; margin-right: 0.1rem"
            src="@/assets/image/f2phone/sun.png"
            alt=""
          />
          <span class="font24 colorf">{{ $t("F2.v77") }}</span>
        </div>
        <img
          style="
            width: 2.64rem;
            height: 5.62rem;
            margin: 1.2rem 2.15rem 0.8rem 2.1rem;
          "
          src="@/assets/image/f2phone/img14.png"
          alt=""
        />
      </div>
    </div>
    <div class="backgm-item11 positionR">
      <div class="title fontBold font52 textaC colorW">{{ $t("F2.v79") }}</div>
      <template v-for="(item, index) in imgM">
        <div
          class="text item backF5 positionR"
          v-if="item.text1 && item.text1 !== ''"
          :key="'text' + index"
        >
          <div>
            <span style="display: flex; align-items: center" v-if="index === 4"
              ><img
                style="width: 1.6rem; height: 0.25rem; margin-right: 0.12rem"
                src="@/assets/image/f2phone/tubiao1.png"
                alt=""
              />{{ $t(item.text1) }}
            </span>
            <span v-else>
              {{ $t(item.text1) }}
            </span>
            <br v-if="index !== 4" />
            <span>{{ $t(item.text2) }}</span>
          </div>
          <!--ios系统 -->
          <sup
            v-if="!browserVisible && index === 11"
            :style="
              huawei
                ? 'margin-top: -0.6rem; margin-left: 2.3rem'
                : 'margin-top: -0.6rem; margin-left: 2rem'
            "
            class="font15 positionA color3A"
            >「4」</sup
          >
          <sup
            v-else-if="browserVisible && index === 11 && ios13 === true"
            class="font15 positionA ios13 color3A"
            >「4」</sup
          >
          <sup
            v-else-if="browserVisible && index === 11 && ios13 === false"
            class="font15 positionA moren color3A"
            >「4」</sup
          >
        </div>
        <div class="img item" v-else :key="'img' + index">
          <img :src="item.img" alt="" />
        </div>
      </template>
      <br style="clear: both" />
    </div>
    <div class="refer font24 color3A backF5">
      <li v-for="(item, index) in refer" :key="'refer' + index">
        <div
          class="referRetract"
          style="padding-left: 0.9rem"
          v-if="index === 5"
        >
          {{ $t(item) }}
        </div>
        <div
          class="referRetract"
          :style="
            index == 4 ? 'padding-left: 0.72rem;text-indent: -0.72rem;' : ''
          "
          v-else
        >
          {{ $t(item) }}
        </div>
      </li>
    </div>
    <div class="de" id="de"></div>
    <div class="info font24">
      <span class="smText width100 color0 textaC marginT30">
        {{ $t("C2.v120") }}
      </span>
      <img
        style="margin-bottom: 0.93rem"
        src="@/assets/image/f2phone/img15.png"
        alt=""
      />
      <div
        class="color0"
        :class="index !== 15 ? 'border' : ''"
        v-for="(item, index) in info"
        :key="'info' + index"
      >
        <span :class="index === 0 ? 'p06' : ''" class="sp1">{{
          $t(item.text1)
        }}</span>
        <span class="sp2 color3A">{{ $t(item.text2) }}</span>
      </div>
    </div>
    <Footer :footerData="footerData" />
    <to-top />
  </div>
</template>

<script>
import ToTop from "../../component/totop/toTop.vue";
import TopNav from "../../component/product/topNav.vue";
import Footer from "../../component/product/footer.vue";
import util from "@/util.js";
import methods from "@/assets/js/methods";
export default {
  name: "f2phone",
  components: {
    TopNav,
    Footer,
    ToTop,
  },
  data() {
    return {
      hh: false,
      topNavData: {
        showMenu: false,
        isShow: false,
        num: 0,
        type: "F2",
        img1: require("@/assets/image/f2phone/img1.png"),
        title: "F2.v1",
        text: "F2.v2",
      },
      footerData: {
        title: "F2.v134",
        type: "F2",
        footer: [
          {
            img1: require("@/assets/image/f2phone/footer-icon1.png"),
            text1: "F2.v135",
            img2: require("@/assets/image/f2phone/footer-icon2.png"),
            text2: "F2.v139",
          },
          {
            img1: require("@/assets/image/f2phone/footer-icon3.png"),
            text1: "F2.v136",
            text3: "F2.v136a",
            img2: require("@/assets/image/f2phone/footer-icon4.png"),
            text2: "F2.v140",
          },
          {
            img1: require("@/assets/image/f2phone/footer-icon5.png"),
            text1: "F2.v137",
            img2: require("@/assets/image/f2phone/footer-icon6.png"),
            text2: "F2.v138",
          },
        ],
      },
      ul1: [
        {
          img: require("@/assets/image/f2phone/black-icon1.png"),
          text: "F2.v22",
        },
        {
          img: require("@/assets/image/f2phone/black-icon2.png"),
          text: "F2.v23",
        },
        {
          img: require("@/assets/image/f2phone/black-icon3.png"),
          text: "F2.v24",
        },
        {
          img: require("@/assets/image/f2phone/black-icon4.png"),
          text: "F2.v25",
        },
      ],
      ul3: [
        {
          img: require("@/assets/image/c2phone/p-25.png"),
          text1: "C2.v44",
          text2: "C2.v45",
        },
        {
          img: require("@/assets/image/c2phone/p-50.png"),
          text1: "C2.v46",
          text2: "C2.v47",
        },
        {
          img: require("@/assets/image/c2phone/p-75.png"),
          text1: "C2.v48",
          text2: "C2.v49",
        },
      ],
      imgM: [
        {
          text1: "F2.v84",
          text2: "F2.v85",
        },
        {
          img: require("@/assets/image/f2phone/M-1.png"),
        },
        {
          img: require("@/assets/image/f2phone/M-2.png"),
        },
        {
          text1: "F2.v88",
          text2: "F2.v89",
        },
        {
          text1: "F2.v86",
          text2: "F2.v87",
        },
        {
          img: require("@/assets/image/f2phone/M-3.png"),
        },
        {
          img: require("@/assets/image/f2phone/M-4.png"),
        },
        {
          text1: "F2.v80",
          text2: "F2.v81",
        },
        {
          text1: "F2.v82",
          text2: "F2.v83",
        },
        {
          img: require("@/assets/image/f2phone/M-5.png"),
        },
        {
          img: require("@/assets/image/f2phone/M-6.png"),
        },
        {
          text1: "F2.v90",
          text2: "F2.v91",
        },
        {
          text1: "F2.v92",
          text2: "F2.v93",
        },
        {
          img: require("@/assets/image/f2phone/M-7.png"),
        },
      ],
      info: [
        {
          text1: "F2.v109",
          text2: "",
        },
        {
          text1: "F2.v110",
          text2: "F2.v111",
        },
        {
          text1: "F2.v112",
          text2: "F2.v113",
        },
        {
          text1: "F2.v114",
          text2: "F2.v115",
        },
        {
          text1: "F2.v116",
          text2: "F2.v117",
        },
        {
          text1: "F2.v118",
          text2: "F2.v119",
        },
        {
          text1: "F2.v120",
          text2: "F2.v121",
        },
        {
          text1: "F2.v122",
          text2: "F2.v123",
        },
        {
          text1: "F2.v124",
          text2: "F2.v125",
        },
        {
          text1: "F2.v126",
          text2: "F2.v127",
        },
        {
          text1: "F2.v128",
          text2: "F2.v129",
        },
        {
          text1: "F2.v130",
          text2: "F2.v131",
        },
        {
          text1: "F2.v132",
          text2: "F2.v133",
        },
      ],
      refer: ["F2.v96", "F2.v97", "F2.v98", "F2.v99", "F2.v100", "F2.v106a"],
      browserVisible: false,
      ios13: false,
      ios15: false,
      huawei: false,
    };
  },
  mounted() {
    let Phonetype = util.isIos();
    if (Phonetype && Phonetype.indexOf("13_") !== -1) {
      //ios13
      this.browserVisible = true;
      this.ios13 = true;
    } else if (Phonetype && !Phonetype.indexOf("15_") !== -1) {
      //ios15
      this.browserVisible = true;
      this.ios15 = true;
      this.ios13 = false;
    } else if (util.isHuaWei()) {
      //华为
      this.browserVisible = false;
      this.huawei = true;
    } else {
      this.browserVisible = false;
    }
    let timer = null;
    setTimeout(() => {
      methods.sizeadapt(750);
    }, 500);
    window.onresize = () => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      timer = setTimeout(() => {
        methods.sizeadapt(750);
      }, 500);
    };
    window.addEventListener("scroll", this.getScroll);
  },
  methods: {
    getScroll() {
      this.topNavData.isShow = false;
      let imgoneTop =
        parseInt(document.querySelector("#brief").getBoundingClientRect().top) -
        50;
      if (imgoneTop < 0) {
        this.topNavData.showMenu = true;
        this.$store.state.isShowHeader = false;
      } else {
        this.topNavData.showMenu = false;
        this.$store.state.isShowHeader = true;
      }
      document.querySelector("#de").style.height = "0px";
    },
  },
};
</script>

<style lang="less">
@import "@/assets/less/screenf2phone.less";
@import "@/assets/less/commonphone.less";
</style>