<template>
  <div class="details">
    <top-nav :topNavData="topNavData" />
    <video
      class="video width100"
      ref="audios"
      id="audios"
      poster="https://image.iriding.cc/video/f2.png"
      x5-video-player-type="h5-page"
      webkit-playsinline="true"
      playsinline="true"
      disablePictureInPicture
      muted
      controls
      controlsList="nofullscreen nodownload noremote footbar noplaybackrate"
    >
      <source src="https://image.iriding.cc/video/f2.mp4" type="video/mp4" />
      您的浏览器不支持Video标签。
    </video>
    <div class="imgone backgrImg height1200" id="brief">
      <div style="margin-right: 0.4rem" class="marginL50">
        <span style="margin-top: 0.9rem" class="bigTextm color0">
          {{ $t("F2.v16") }} </span
        ><br />
        <span class="bigTextm color0"> {{ $t("F2.v17") }} </span><br />
        <span style="margin-top: 0.3rem" class="smTextf color3A"
          >{{ $t("F2.v18") }}{{ $t("F2.v19") }}{{ $t("F2.v20") }}</span
        >
      </div>
    </div>
    <div style="padding-top: 0.5rem" class="imgtwo height1200 back24">
      <!-- <div class="item-top"> -->
      <div
        style="
          padding: 0.18rem 0 0.18rem 0;
          background: #8b8b8b;
          border-top-left-radius: 0.35rem;
          border-top-right-radius: 0.35rem;
          margin: 0 0.35rem 0 0.35rem;
          display: flex;
        "
      >
        <span
          style="line-height: 25px; width: 100%"
          class="title font38 fontBold color0 textaC"
          >{{ $t("F2.v21") }}</span
        >
      </div>
      <div
        style="
          width: 6.8rem;
          height: 6rem;
          background: #000;
          margin: 0 0.35rem 0 0.35rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom-left-radius: 0.35rem;
          border-bottom-right-radius: 0.35rem;
        "
      >
        <img
          style="width: 5.73rem; height: 4.41rem"
          src="@/assets/image/f2phone/img41.png"
          alt=""
        />
      </div>
      <!-- </div> -->
      <ul>
        <li v-for="(item, index) in ul1" :key="'ul1' + index">
          <div>
            <img :class="'img' + index" :src="item.img" alt="" />
          </div>
          <span class="colorW font27">{{ $t(item.text) }}</span>
        </li>
      </ul>
    </div>
    <div class="imgthree">
      <div
        style="padding-bottom: 0.45rem"
        class="marginL50 marginR50 marginT100"
      >
        <span class="color0 bigTextm">{{ $t("F2.v26") }}</span>
        <span class="color0 bigTextm">{{ $t("F2.v27") }}</span>
        <span class="smTextf color3A marginT30" v-html="$t('F2.v28m')"> </span>
        <span class="color3A font27 fw400">{{ $t("F2.v29") }}</span>
        <img style="" src="@/assets/image/f2phone/img5.png" alt="" />
        <span class="font24 color4e">{{ $t("F2.v33") }}</span>
      </div>
    </div>
    <div class="imgfour backgrImg textaR">
      <span class="color3A smTextf marginR50 margin54">{{ $t("F2.v34") }}</span>
      <br />
      <span class="color3A smTextf marginR50"
        >{{ $t("F2.m34a") }} <sup style="margin-left: -0.1rem">3</sup>
      </span>
    </div>
    <div class="imgfive backgrImg height1200">
      <div class="marginL50 marginR50">
        <span class="color0 bigTextm marginT100">{{ $t("F2.v35") }}</span
        ><br />
        <span class="color0 bigTextm">{{ $t("F2.v36") }}</span>
        <span class="color3A smTextf marginT30"
          >{{ $t("F2.v37") }}{{ $t("F2.v38") }}{{ $t("F2.v38a") }}</span
        ><br />
        <span class="color4e smTextf positionA bottom83"
          >{{ $t("F2.v39") }}{{ $t("F2.v40") }}{{ $t("F2.v41") }}</span
        >
      </div>
    </div>
    <img style="width: 100%" src="@/assets/image/f2phone/img8.png" alt="" />
    <div class="imgsix">
      <div style="padding-bottom: 0.3rem" class="marginL50 marginR50">
        <img
          class="marginT100"
          src="@/assets/image/f2phone/tubiao1.png"
          alt=""
        />
        <br />
        <span class="color0 bigTextm">{{ $t("F2.v43m") }}</span
        ><br />
        <span style="margin-top: 0.3rem" class="color3A smTextf"
          >{{ $t("F2.v45") }}{{ $t("F2.v46") }}{{ $t("F2.v47")
          }}{{ $t("F2.v48") }}{{ $t("F2.m47c") }}</span
        >
      </div>
      <img
        style="width: 7.5rem; height: 6.92rem"
        src="@/assets/image/f2phone/pic3.png"
        alt=""
      />
    </div>
    <div class="content2">
      <span class="bigTextm textaC width100 colorW">{{ $t("F2.v50") }}</span
      ><br />
      <span
        class="bigTextm textaC width100 colorW"
        v-html="$t('F2.v51m')"
      ></span>
      <div
        style="
          background: #fff;
          border-radius: 0.4rem;
          margin: 0 0.25rem 0 0.25rem;
        "
      >
        <div style="padding: 1rem 0 0.4rem 0; margin: 0 0.5rem 0 0.5rem">
          <span class="color0 bigTextm" v-html="$t('F2.v52m')"></span>
          <span
            style="margin-top: 0.3rem"
            class="color3A smTextf"
            v-html="$t('F2.v54m')"
          ></span>
        </div>
        <img
          style="width: 7rem; height: 7.1rem"
          src="@/assets/image/f2phone/pic4.png"
          alt=""
        />
      </div>
      <div class="imgTwo backgrImg">
        <div class="positionR">
          <div style="margin-left: 0.5rem; margin-right: 0.5rem">
            <span class="color0 bigTextm" v-html="$t('F2.v56m')"></span>
            <span class="smTextf color3A">
              {{ $t("F2.v58m")
              }}<sup
                style="margin-left: -0.03rem; margin-top: 0rem"
                class="font15 color3A positionA"
                >「2」</sup
              ></span
            >
            <div style="display: flex">
              <div
                style="display: flex; margin-top: 0.4rem"
                class="item1 floatL"
              >
                <span class="font24 color567">{{ $t("F2.v60") }}</span>
                <div style="display: flex">
                  <img
                    style="
                      width: 0.74rem;
                      height: 0.18rem;
                      margin-top: 0.11rem;
                      margin-left: 0.05rem;
                    "
                    src="@/assets/image/c2phone/p-icon1.png"
                    alt=""
                  />
                </div>
              </div>
              <div
                style="display: flex; margin-top: 0.4rem"
                class="item1 floatL marginL50"
              >
                <span class="font24 colorA18">{{ $t("F2.v61") }}</span>
                <div style="display: flex">
                  <img
                    style="
                      width: 0.74rem;
                      height: 0.18rem;
                      margin-top: 0.11rem;
                      margin-left: 0.05rem;
                    "
                    src="@/assets/image/c2phone/p-icon2.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <br style="clear: both" />
            <ul style="margin-bottom: 0.15rem">
              <li
                :class="index === 0 || index === 1 ? 'marginR30' : ''"
                class="font24"
                v-for="(item, index) in ul3"
                :key="'ul3' + index"
                style="width: 30%"
              >
                <img
                  style="
                    width: 1.1rem;
                    height: 1.1rem;
                    margin-top: 0.3rem;
                    margin-bottom: 0.18rem;
                  "
                  :src="item.img"
                  alt=""
                />
                <span>{{ $t(item.text1) }}</span>
                <span
                  style="display: block; width: 100%; word-break: break-all"
                  >{{ $t(item.text2) }}</span
                >
              </li>
              <br style="clear: both" />
            </ul>
          </div>
        </div>
        <img
          style="width: 100%"
          src="@/assets/image/f2phone/img11.png"
          alt=""
        />
      </div>
      <div
        style="
          border-radius: 0.45rem;
          margin: 0.3rem 0.25rem 0 0.25rem;
          background: #fff;
        "
        class="backgrImg"
      >
        <div
          style="
            padding-bottom: 0.33rem;
            padding-top: 1rem;
            margin: 0 0.5rem 0 0.5rem;
          "
        >
          <span class="color0 bigTextm"
            >{{ $t("F2.v66") }}{{ $t("F2.v67") }}</span
          >
          <span style="margin-top: 0.3rem" class="smTextf color3A">{{
            $t("F2.v68")
          }}</span>
        </div>
        <img
          style="width: 7.01rem; height: 7.8rem"
          src="@/assets/image/f2phone/pic1.png"
          alt=""
        />
      </div>
    </div>
    <div class="content4">
      <div class="backgrImg">
        <div>
          <span
            style="margin-top: 0.57rem; line-height: 25px"
            class="color0 bigTextm"
            >{{ $t("F2.v69b") }}
          </span>

          <span style="line-height: 27px" class="color0 bigTextm">{{
            $t("F2.v69a")
          }}</span>
          <span
            style="margin-top: 0.2rem"
            class="smTextf color3A"
            v-html="$t('F2.v70')"
          ></span>
          <div>
            <div style="width: 2.2rem; float: left; margin-top: 0.3rem">
              <img
                style="width: 0.79rem; height: 0.79rem; margin-bottom: 0.2rem"
                src="@/assets/image/f2phone/s-icon1.png"
                alt=""
              />
              <span
                style="word-break: break-all"
                class="font24 fw400 color3A textaC"
                >{{ $t("F2.v73") }}</span
              >
            </div>
            <div
              style="
                width: 2.2rem;
                float: left;
                margin-left: 0.3rem;
                margin-top: 0.3rem;
              "
            >
              <img
                style="width: 0.79rem; height: 0.79rem; margin-bottom: 0.2rem"
                src="@/assets/image/f2phone/s-icon2.png"
                alt=""
              />
              <span
                style="word-break: break-all"
                class="font24 color4e fw400 textaC"
                >{{ $t("F2.v74") }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content3">
      <div class="item1 marginL50 marginR50">
        <span class="color0 bigTextm marginT100" v-html="$t('F2.v75')"></span
        ><br />
        <!-- <span class="color0 bigTextm">{{ $t("F2.v75a") }}</span
        ><br /> -->
        <span
          v-if="ios15 === true && de === true"
          style="position: relative"
          class="color3A smTextf marginT30"
          v-html="$t('F2.v76n')"
        >
        </span>
        <span
          v-else
          style="position: relative"
          class="color3A smTextf marginT30"
          v-html="$t('F2.v76m')"
        >
        </span>
        <!-- <div class="marginT30">
          <img
            style="width: 0.33rem; height: 0.34rem; margin-right: 0.1rem"
            src="@/assets/image/f2phone/sun.png"
            alt=""
          />
          <span class="font24 colorf">{{ $t("F2.v77") }}</span>
        </div> -->
        <img
          style="
            width: 2.64rem;
            height: 5.62rem;
            margin: 1.2rem 2.15rem 0.8rem 2.1rem;
          "
          src="@/assets/image/f2phone/img14.png"
          alt=""
        />
      </div>
    </div>
    <div class="backgm-item11 positionR">
      <div
        style="padding: 0.8rem 0 0.8rem 0; background: #404349; width: 100%"
        class="bigTextm textaC colorW"
        v-html="$t('F2.v79m')"
      ></div>
      <template v-for="(item, index) in imgM">
        <div
          class="text item font24 backF5 positionR"
          v-if="item.text1 && item.text1 !== ''"
          :key="'text' + index"
        >
          <div style="margin: 0 0.15rem 0 0.15rem">
            <span style="display: flex" v-if="index === 4">
              <img
                style="width: 1.6rem; height: 0.25rem"
                src="@/assets/image/f2phone/tubiao1.png"
                alt=""
              /><span>{{ $t(item.text1) }}</span>
            </span>
            <span v-else v-html="$t(item.text1)"> </span>
            <br v-if="index !== 4" />
            <span v-html="$t(item.text2)"></span>
          </div>
          <!--ios系统 -->
          <!-- <sup
            v-if="!browserVisible && index === 11"
            :style="
              huawei
                ? 'margin-top: -0.6rem; margin-left: 2.3rem'
                : 'margin-top: -0.6rem; margin-left: 2rem'
            "
            class="font15 positionA color3A"
            >「4」</sup
          >
          <sup
            v-else-if="browserVisible && index === 11 && ios13 === true"
            class="font15 positionA ios13 color3A"
            >「4」</sup
          >
          <sup
            v-else-if="
              browserVisible &&
              index === 11 &&
              (ios13 === false || ios15 === true)
            "
            class="font15 positionA moren color3A"
            :style="
              ios15
                ? !es
                  ? 'margin-top: -0.3rem;margin-left: 1.2rem;'
                  : 'margin-left: 0.4rem;margin-top: -0.1rem;'
                : ''
            "
            >「4」</sup
          > -->
        </div>
        <div class="img item" v-else :key="'img' + index">
          <img :src="item.img" alt="" />
        </div>
      </template>
      <br style="clear: both" />
    </div>
    <div class="refer font24 color3A backF5">
      <li
        style="line-height: 25px"
        v-for="(item, index) in refer"
        :key="'refer' + index"
      >
        <div
          class="referRetract"
          style="padding-left: 0.8rem"
          v-if="index === 5"
        >
          {{ $t(item) }}
        </div>
        <div
          class="referRetract"
          :style="
            index == 4 ? 'padding-left: 0.72rem;text-indent: -0.72rem;' : ''
          "
          v-else
        >
          {{ $t(item) }}
        </div>
      </li>
      <div style="margin: 0.4rem 0.5rem 0 0.5rem; line-height: 25px">
        <div>{{ $t("F2.v99a") }}</div>
        <div>
          <span
            style="
              display: inline-block;
              text-indent: -0.3rem;
              padding-left: 0.3rem;
            "
            >{{ $t("F2.v100") }}</span
          >
          <br />
          <span
            style="
              display: inline-block;
              text-indent: -0.3rem;
              padding-left: 0.3rem;
            "
            >{{ $t("F2.v106a") }}</span
          >
        </div>
      </div>
    </div>
    <div class="de" id="de"></div>
    <div class="info font24">
      <span class="smTextf width100 color0 textaC marginT30">
        {{ $t("C2.v120") }}
      </span>
      <img
        style="margin-bottom: 0.93rem"
        src="@/assets/image/f2phone/img15.png"
        alt=""
      />
      <div
        :class="index !== 12 ? 'border' : ''"
        v-for="(item, index) in info"
        :key="'info' + index"
        style="
          height: auto;
          display: flex;

          flex-direction: column;
          line-height: 0.35rem;
        "
        :style="
          index === 15
            ? 'padding: 0.3rem 0 0.5rem 0;'
            : 'padding: 0.3rem 0 0.3rem 0;'
        "
      >
        <span class="color0">{{ $t(item.text1) }}</span>
        <span class="color58">{{ $t(item.text2) }}</span>
      </div>
      <!-- <div
        class="color0"
        :class="index !== 15 ? 'border' : ''"
        v-for="(item, index) in info"
        :key="'info' + index"
      >
        <span :class="index === 0 ? 'p06' : ''" class="sp1">{{
          $t(item.text1)
        }}</span>
        <span class="sp2 color3A">{{ $t(item.text2) }}</span>
      </div> -->
    </div>
    <div class="footer">
      <div class="title font47 border p06 fontBold width100">
        {{ $t(footerData.title) }}
      </div>
      <div
        :class="index === 4 ? 'bottom69' : ''"
        class="mainItem"
        v-for="(item, index) in footerData.footer"
        :key="'footer' + index"
      >
        <div :class="footerData.type === 'F2' ? 'itemF2' : 'itemC2'">
          <div>
            <img
              style="margin-bottom: 0.5rem"
              :src="item.img1"
              :class="'img1' + index"
              :style="
                Multi
                  ? index === 2
                    ? ' width: 1.97rem !important;height: 0.75rem !important;'
                    : ''
                  : ''
              "
              alt=""
            />
          </div>
          <div v-if="item.text2">
            <img
              style="margin-bottom: 0.5rem"
              :src="item.img2"
              :class="'img2' + index"
              alt=""
            />
          </div>
        </div>
        <div class="item2">
          <div
            style="word-break: break-all; padding-right: 0.4rem"
            :style="
              item.text3 && item.text3 !== '' ? 'flex-direction: column;' : ''
            "
          >
            <span class="textaC color3A fw400" v-html="$t(item.text1)"></span>
            <span
              style="text-align: center"
              v-if="item.text3 && item.text3 !== ''"
              >{{ $t(item.text3) }}</span
            >
          </div>
          <div v-if="item.text2" style="word-break: break-all">
            <span class="textaC color3A fw400">{{ $t(item.text2) }}</span>
          </div>
        </div>
      </div>
    </div>
    <to-top />
  </div>
</template>

<script>
import ToTop from "../../component/totop/toTop.vue";
import TopNav from "../../component/product/topNav.vue";
import util from "@/util.js";
import methods from "@/assets/js/methods";
export default {
  name: "f2phone",
  components: {
    TopNav,
    ToTop,
  },
  data() {
    return {
      hh: false,
      topNavData: {
        showMenu: false,
        isShow: false,
        num: 0,
        type: "F2",
        img1: require("@/assets/image/f2phone/img1.png"),
        title: "F2.v1",
        text: "F2.v2",
        title2: "F2.v1m",
      },
      footerData: {
        title: "F2.v134",
        type: "F2",
        footer: [
          {
            img1: require("@/assets/image/f2phone/footer-icon1.png"),
            text1: "F2.v135",
            img2: require("@/assets/image/f2phone/footer-icon2.png"),
            text2: "F2.v139",
          },
          {
            img1: require("@/assets/image/f2phone/footer-icon3.png"),
            text1: "F2.v136",
            img2: require("@/assets/image/f2phone/footer-icon5.png"),
            text2: "F2.v137",
          },
          {
            img1: require("@/assets/image/f2phone/footer-icon6.png"),
            text1: "F2.v138",
          },
        ],
      },
      ul1: [
        {
          img: require("@/assets/image/f2phone/black-icon1.png"),
          text: "F2.v22",
        },
        {
          img: require("@/assets/image/f2phone/black-icon2.png"),
          text: "F2.v23",
        },
        {
          img: require("@/assets/image/f2phone/black-icon3.png"),
          text: "F2.v24",
        },
        {
          img: require("@/assets/image/f2phone/black-icon4.png"),
          text: "F2.v25",
        },
      ],
      ul3: [
        {
          img: require("@/assets/image/f2phone/pm-25.png"),
          text1: "F2.v62",
          text2: "F2.v62a",
        },
        {
          img: require("@/assets/image/f2phone/pm-50.png"),
          text1: "F2.v63",
          text2: "F2.v63a",
        },
        {
          img: require("@/assets/image/f2phone/pm-75.png"),
          text1: "F2.v64",
          text2: "F2.v64a",
        },
      ],
      imgM: [
        {
          text1: "F2.v84",
          text2: "F2.v85",
        },
        {
          img: require("@/assets/image/f2phone/M-1.png"),
        },
        {
          img: require("@/assets/image/f2phone/M-2.png"),
        },
        {
          text1: "F2.v88",
          text2: "F2.v89",
        },
        {
          text1: "F2.v86m",
          text2: "F2.v87",
        },
        {
          img: require("@/assets/image/f2phone/M-3.png"),
        },
        {
          img: require("@/assets/image/f2phone/M-4.png"),
        },
        {
          text1: "F2.v80",
          text2: "F2.v81",
        },
        {
          text1: "F2.v82",
          text2: "F2.v83",
        },
        {
          img: require("@/assets/image/f2phone/M-5.png"),
        },
        {
          img: require("@/assets/image/f2phone/M-6.png"),
        },
        {
          text1: "F2.v90m",
          text2: "F2.v91",
        },
        {
          text1: "F2.v92",
          text2: "F2.v93",
        },
        {
          img: require("@/assets/image/f2phone/M-7.png"),
        },
      ],
      info: [
        {
          text1: "F2.v109",
          text2: "",
        },
        {
          text1: "F2.v110",
          text2: "F2.v111",
        },
        {
          text1: "F2.v112",
          text2: "F2.v113",
        },
        {
          text1: "F2.v114",
          text2: "F2.v115",
        },
        {
          text1: "F2.v116",
          text2: "F2.v117",
        },
        {
          text1: "F2.v118",
          text2: "F2.v119",
        },
        {
          text1: "F2.v120",
          text2: "F2.v121",
        },
        {
          text1: "F2.v122",
          text2: "F2.v123",
        },
        {
          text1: "F2.v124",
          text2: "F2.v125",
        },
        {
          text1: "F2.v126",
          text2: "F2.v127",
        },
        {
          text1: "F2.v128",
          text2: "F2.v129",
        },
        {
          text1: "F2.v130",
          text2: "F2.v131",
        },
        {
          text1: "F2.v132",
          text2: "F2.v133",
        },
      ],
      refer: ["F2.v96", "F2.v97", "F2.v98", "F2.v99"],
      browserVisible: false,
      ios13: false,
      ios15: false,
      huawei: false,
      de: false,
      es: false,
      scrolldata: {
        isShow: "",
        topdistance: "",
        target: "",
      },
      Multi: false,
    };
  },
  mounted() {
    if (!["zh-cn", "zh-hk"].includes(localStorage.getItem("lans"))) {
      this.Multi = true;
    }

    let Phonetype = util.isIos();
    if (["de"].includes(localStorage.getItem("lans"))) {
      this.de = true;
    }
    if (["es"].includes(localStorage.getItem("lans"))) {
      this.es = true;
    }
    if (Phonetype && Phonetype.indexOf("13_") !== -1) {
      this.browserVisible = true;
      this.ios13 = true;
    } else if (Phonetype && !Phonetype.indexOf("15_") !== -1) {
      this.browserVisible = true;
      this.ios15 = true;
      this.ios13 = false;
    } else if (util.isHuaWei()) {
      //华为
      this.browserVisible = false;
      this.huawei = true;
    } else {
      this.browserVisible = false;
    }
    let timer = null;
    setTimeout(() => {
      methods.sizeadapt(750);
    }, 500);
    window.onresize = () => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      timer = setTimeout(() => {
        methods.sizeadapt(750);
      }, 500);
    };
    window.addEventListener("scroll", this.getScroll);
  },
  methods: {
    getScroll() {
      this.scrolldata = {
        isShow: this.topNavData.isShow,
        showMenu: this.topNavData.showMenu,
        topid: "#brief",
        target: "#de",
      };
      let data = methods.getScroll(this.scrolldata);
      this.topNavData.showMenu = data.showMenu;
      this.topNavData.isShow = data.isShow;
    },
  },
};
</script>

<style lang="less">
@import "@/assets/less/screenf2phone.less";
@import "@/assets/less/commonphone.less";
.footer {
  font-size: 0.24rem !important;
  .img10 {
    width: 1.99rem !important;
    height: 1.52rem !important;
  }
  .img20 {
    width: 1.15rem !important;
    height: 1.57rem !important;
  }
  .img11 {
    width: 1.22rem !important;
    height: 1.05rem !important;
  }
  .img21 {
    width: 1.75rem !important;
    height: 1.34rem !important;
  }
}
</style>